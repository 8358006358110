import axios from "axios";

class Rpc {
    axios
    path

    constructor(axios, path) {
        this.axios = axios
        this.path = path
    }

    execute(method, params = {}) {
        return new Promise((resolve, reject) => {
            this.axios.post(this.path, {
                jsonrpc: "2.0",
                id: method + "request",
                method: method,
                params: params
            }, {withCredentials: true, headers: {client_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone}}).then((response) => {
                response = response.data
                if (response.error) {
                    if (response.error.code === -32000 && method !== 'validate') {
                        window.location.reload()
                    }
                    reject(response.error)
                    return
                }
                resolve(response.result)
            }).catch(() => {
                reject({
                    code: -32603,
                    message: 'Internal frontend error'
                })
            })
        })
    }
}

export default new Rpc(axios, '/rpc/v1')

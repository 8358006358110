<template>
  <div class="col-12 md:col-6 lg:col-4">
    <Card v-if="isAuth">
      <template #title>БКИ Сервис</template>
      <template #subtitle>Авторизация</template>
      <template #content>
        <AbstractForm
          v-model="loginForm"
          :loading="loading"
          :on-commit="login"
          not-show-clear
          commit-label="Войти"
          commit-icon="pi pi-sign-in"
        ></AbstractForm>
      </template>
      <template #footer>
        <div class="flex col-12 justify-content-center">
          <a href="#" @click="isAuth = false">Забыли пароль?</a>
        </div>
      </template>
    </Card>
    <Card v-if="!isAuth">
      <template #title>БКИ Сервис</template>
      <template #subtitle>Восстановление пароля</template>
      <template #content>
        <AbstractForm
            v-model="resetPasswordForm"
            :loading="loading"
            :on-commit="resetPassword"
            not-show-clear
            commit-label="Сбросить пароль"
        ></AbstractForm>
      </template>
      <template #footer>
        <div class="flex col-12 justify-content-center">
          <a href="#" @click="isAuth = true">Назад</a>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import AbstractForm from "@/components/AbstractForm";
import FormModel from "@/model/FormModel";
import AuthService from "@/service/AuthService";
import ResponseService from "@/service/ResponseService";
export default {
  name: "Auth",
  components: {AbstractForm},
  data() {
    return {
      isAuth: true,
      loading: false,
      loginForm: {
        email: new FormModel('E-mail'),
        password: new FormModel('Пароль', {type: 'password'})
      },
      resetPasswordForm: {
        email: new FormModel('E-mail'),
      }
    }
  },
  methods: {
    resetPassword() {
      this.loading = true
      AuthService.resetPassword(this.resetPasswordForm.email.value).then(() => {
        this.$toast.add({
          severity: 'success',
          summary: 'Пароль сброшен',
          detail: 'Новый пароль был выслан на вашу почту',
          life: 5000
        })
        setTimeout(() => {
          window.location.reload()
        }, 5000)
      }).catch((err) => {
        ResponseService.handleErrorResponseWithForm(err, this.resetPasswordForm, this.$toast)
        this.loading = false
      })
    },
    login() {
      this.loading = true
      AuthService.login(this.loginForm.email.value, this.loginForm.password.value).then((res) => {
        if (res.user !== null && res.error === null) {
          this.$store.state.user = res.user
          this.loading = false
        }
        if (res.error !== null) {
          this.loginForm.email.state = false
          this.loginForm.email.feedback = res.error
          this.loading = false
        }
      }).catch((err) => {
        ResponseService.handleErrorResponseWithForm(err, this.loginForm, this.$toast)
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
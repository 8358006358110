import rpc from "@/rpc";
import ResponseService from "@/service/ResponseService";

class CompendiumService {
    rpc
    responseService

    constructor(rpc, responseService) {
        this.rpc = rpc
        this.responseService = responseService
    }

    init(toast, after) {
        this.rpc.execute('init').then((res) => {
            let compendiums = {}
            for (let k in res.compendiums) {
                compendiums[k] = []
                for (let ck in res.compendiums[k]) {
                    compendiums[k].push({
                        code: ck,
                        value: res.compendiums[k][ck],
                    })
                }
            }
            after(compendiums, res.tables)
        }).catch((err) => {
            this.responseService.handleErrorResponseWithoutForm(err, toast)
        })
    }

    getValueByCode(compendium, code) {
        for (let c in compendium) {
            if (compendium[c].code === code) {
                return compendium[c].value
            }
        }
        return code
    }
}

export default new CompendiumService(rpc, ResponseService)
import FormService from "@/service/FormService";

export default new class ResponseService {
    handleErrorResponseWithForm(err, form, toast) {
        if (err.code === -32602 && Array.isArray(err.data)) {
            FormService.processErrorForm(form, err.data)
            return
        }
        FormService.clearForm(form)
        toast.add({
            severity: 'error',
            summary: 'Ошибка',
            detail: typeof err.data === 'string' ? err.data : 'Внутренняя ошибка обработки',
            life: 2000
        })
    }

    handleErrorResponseWithoutForm(err, toast) {
        toast.add({
            severity: 'error',
            summary: 'Ошибка',
            detail: typeof err.data === 'string' ? err.data : 'Внутренняя ошибка обработки',
            life: 2000
        })
    }

    handleFeedbackResponse(data, toast) {
        toast.add({
            severity: 'success',
            summary: 'Выполнено',
            detail: data.feedback,
            life: 2000
        })
    }
}
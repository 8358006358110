<template>
  <div>
    <div class="p-fluid grid">
      <div class="field col-12" v-for="(v, k) in modelValue" :key="k">
        <label>{{ v.label }}</label>
        <Calendar
          :disabled="loading"
          v-if="v.type === 'date'"
          v-model="v.value"
          date-format="dd.mm.yy"
          @input="updateValue(k, $event.target.value); v.state = null; v.feedback = null"
          @change="v.state = null; v.feedback = null"
          :class="v.state === false ? 'p-invalid' : ''"
        ></Calendar>
        <Editor
          editor-style="height: 320px"
          :disabled="loading"
          v-if="v.type === 'editor'"
          v-model="v.value"
        >
          <template #toolbar>
            <span class="ql-formats">
              <select class="ql-size">
                <option value="normal">Обычный</option>
                <option value="large">Большой</option>
              </select>
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button>
              <button class="ql-link"></button>
              <button class="ql-list" value="bullet"></button>
            </span>
          </template>
        </Editor>
        <Calendar
          :disabled="loading"
          time-only
          v-if="v.type === 'time'"
          v-model="v.value"
          show-time
          hour-format="24"
          date-format=""
          time-separator=":"
          @input="updateValue(k, $event.target.value); v.state = null; v.feedback = null"
          :class="v.state === false ? 'p-invalid' : ''"
        ></Calendar>
        <Password
          :disabled="loading"
          v-if="v.type === 'password'"
          v-model="v.value"
          toogleMask
          :feedback="false"
          @input="v.state = null; v.feedback = null"
          :class="v.state === false ? 'p-invalid' : ''"
        ></Password>
        <InputNumber
          :disabled="loading"
          v-if="v.type === 'number'"
          :value="v.value"
          @input="updateValue(k, $event.value); v.state = null; v.feedback = null"
          :class="v.state === false ? 'p-invalid' : ''"
        ></InputNumber>
        <Textarea
          auto-resize
          :disabled="loading"
          v-if="v.type === 'textarea'"
          :value="v.value"
          @input="updateValue(k, $event.target.value); v.state = null; v.feedback = null"
          :placeholder="v.placeholder"
          :class="v.state === false ? 'p-invalid' : ''"
        ></Textarea>
        <InputText
          :disabled="loading"
          v-if="v.type === 'text'"
          :value="v.value"
          @input="updateValue(k, $event.target.value); v.state = null; v.feedback = null"
          :placeholder="v.placeholder"
          :class="v.state === false ? 'p-invalid' : ''"
        ></InputText>
        <Dropdown
          filter
          show-clear
          v-if="v.type === 'select'"
          :disabled="loading"
          :loading="v.loading"
          :option-value="v.selectValue"
          :option-label="v.selectOption"
          :options="v.selectOptions"
          v-model="v.value"
          @input="updateValue(k, $event.target.value); v.state = null; v.feedback = null"
          @change="v.state = null; v.feedback = null"
          :class="v.state === false ? 'p-invalid' : ''"
        >
          <template #emptyfilter>Не найдено</template>
        </Dropdown>
        <small v-if="v.state === false" class="p-error">{{ v.feedback }}</small>
      </div>
    </div>
    <div class="grid">
      <div class="col-12">
        <div class="flex justify-content-between">
          <Button :loading="loading" :icon="commitIcon || 'pi pi-check'" :label="commitLabel" class="p-button-success" @click="onCommit()"></Button>
          <Button v-if="!notShowClear" :loading="loading" icon="pi pi-times" class="p-button-warning" @click="clear"></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormModel from "@/model/FormModel";

export default {
  name: "AbstractForm",
  props: {
    modelValue: {
      type: Object,
    },
    onCommit: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean
    },
    notShowClear: {
      type: Boolean
    },
    commitLabel: {
      type: String
    },
    commitIcon: {
      type: String
    },
    afterClear: {
      type: Function
    }
  },
  methods: {
    updateValue(key, value) {
      let d = new FormModel()
      Object.assign(d, this.modelValue[key])
      d.value = value === null || value === '' ? null : value
      d.state = null
      d.feedback = null
      this.$emit('update:modelValue', {...this.modelValue, [key]: d})
    },
    clear() {
      let newModel = {}
      for (let k in this.modelValue) {
        newModel[k] = this.modelValue[k]
        newModel[k].state = null
        newModel[k].feedback = null
        newModel[k].value = null
      }
      this.$emit('update:modelValue', newModel)
      if (this.afterClear) {
        this.afterClear()
      }
    }
  }
}
</script>

<style scoped>

</style>
import { createApp } from 'vue'
import App from './App.vue'
import { createStore } from "vuex";

import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import 'primevue/resources/themes/lara-light-teal/theme.css'
import 'primevue/resources/primevue.css'

import PrimeVue from "primevue/config";
import router from "@/router";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import DialogService from "primevue/dialogservice";

const store = createStore({
    state() {
        return {
            user: null,
            compendiums: [],
            tables: {},
            processedTables: {}
        }
    },
    getters: {
        table: (state) => (code) => {
            if (state.processedTables[code]) {
                return state.processedTables[code]
            }
            let table = state.tables[code]
            for (let i in table) {
                if (table[i].filter && table[i].filter.type === 'select') {
                    table[i].filter.items = state.compendiums[table[i].filter.items] ?? []
                    table[i].filter.optionValue = 'code'
                    table[i].filter.optionLabel = 'value'
                }
            }
            state.processedTables[code] = table
            return table
        },
    }
})

const app = createApp(App)
app.use(router)
app.use(store)
app.use(PrimeVue)
app.use(ToastService)
app.use(ConfirmationService)
app.use(DialogService)

import TabMenu from "primevue/tabmenu";
app.component('TabMenu', TabMenu)
import Card from "primevue/card";
app.component('Card', Card)
import DataTable from "primevue/datatable";
app.component('DataTable', DataTable)
import Column from "primevue/column";
app.component('Column', Column)
import InputText from "primevue/inputtext";
app.component('InputText', InputText)
import InputMask from "primevue/inputmask";
app.component('InputMask', InputMask)
import InputNumber from "primevue/inputnumber";
app.component('InputNumber', InputNumber)
import Dropdown from "primevue/dropdown";
app.component('Dropdown', Dropdown)
import Button from "primevue/button";
app.component('Button', Button)
import Calendar from "primevue/calendar";
app.component('Calendar', Calendar)
import Toast from "primevue/toast";
app.component('Toast', Toast)
import ProgressSpinner from "primevue/progressspinner";
app.component('ProgressSpinner', ProgressSpinner)
import PickList from "primevue/picklist";
app.component('PickList', PickList)
import Accordion from "primevue/accordion";
app.component('Accordion', Accordion)
import AccordionTab from "primevue/accordiontab";
app.component('AccordionTab', AccordionTab)
import Menubar from "primevue/menubar";
app.component('Menubar', Menubar)
import Password from "primevue/password";
app.component('Password', Password)
import Avatar from "primevue/avatar";
app.component('Avatar', Avatar)
import Textarea from "primevue/textarea";
app.component('Textarea', Textarea)
import TabView from "primevue/tabview";
app.component('TabView', TabView)
import TabPanel from "primevue/tabpanel";
app.component('TabPanel', TabPanel)
import Editor from "primevue/editor";
app.component('Editor', Editor)
import OverlayPanel from "primevue/overlaypanel";
app.component('OverlayPanel', OverlayPanel)
import SplitButton from "primevue/splitbutton";
app.component('SplitButton', SplitButton)
import Divider from "primevue/divider";
app.component('Divider', Divider)
import VirtualScroller from "primevue/virtualscroller";
app.component('VirtualScroller', VirtualScroller)
import Panel from "primevue/panel";
app.component('Panel', Panel)
import Message from "primevue/message";
app.component('Message', Message)
import ContextMenu from "primevue/contextmenu";
app.component('ContextMenu', ContextMenu)
import Chip from "primevue/chip";
app.component('Chip', Chip)
import SpeedDial from "primevue/speeddial";
app.component('SpeedDial', SpeedDial)
import ConfirmDialog from "primevue/confirmdialog";
app.component('ConfirmDialog', ConfirmDialog)
import DynamicDialog from "primevue/dynamicdialog";
app.component('DynamicDialog', DynamicDialog)
import FileUpload from "primevue/fileupload";
app.component('FileUpload', FileUpload)
import ProgressBar from "primevue/progressbar";
app.component('ProgressBar', ProgressBar)


app.config.globalProperties.$filters = {
    currency(val) {
        if (val === null) {
            return null
        }
        if (typeof val === 'string') {
            val = parseFloat(val)
        }
        let formatter = new Intl.NumberFormat('ru-RU', {
            style: 'currency',
            currency: 'RUB'
        })
        return formatter.format(val)
    },
    boolean(val) {
        return val === true ? 'Да' : 'Нет';
    },
    translate(store, compendiumName, code) {
        let compendium = store.state.compendiums[compendiumName]
        if (compendium) {
            for (let c in compendium) {
                if (compendium[c].code === code) {
                    return compendium[c].value
                }
            }
        }
        return code
    }
}

app.mount('#app')

export default app;

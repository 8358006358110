<template>
  <div class="col-12 md:col-6 lg:col-4">
    <Card>
      <template #title>БКИ Сервис</template>
      <template #subtitle>Изменение пароля</template>
      <template #content>
        <AbstractForm v-model="changePasswordForm" :loading="loading" :on-commit="changePassword" commit-label="Изменить пароль"></AbstractForm>
      </template>
    </Card>
  </div>
</template>

<script>
import AbstractForm from "@/components/AbstractForm";
import FormModel from "@/model/FormModel";
import AuthService from "@/service/AuthService";
import ResponseService from "@/service/ResponseService";
export default {
  name: "ChangePassword",
  components: {AbstractForm},
  data() {
    return {
      loading: false,
      changePasswordForm: {
        password: new FormModel('Пароль', {type: 'password'}),
        repeatPassword: new FormModel('Повторите пароль', {type: 'password'}),
      }
    }
  },
  methods: {
    changePassword() {
      this.loading = true
      AuthService.changePassword(
          this.changePasswordForm.password.value,
          this.changePasswordForm.repeatPassword.value
      ).then((res) => {
        this.$store.state.user = res
        this.loading = false
      }).catch((err) => {
        ResponseService.handleErrorResponseWithForm(err, this.changePasswordForm, this.$toast)
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
import rpc from "@/rpc";

class AuthService {
    rpc

    constructor(rpc) {
        this.rpc = rpc
    }

    login(email, password) {
        return this.rpc.execute('login', {
            email: email === null ? '' : email,
            password: password === null ? '' : password
        })
    }

    validate() {
        return this.rpc.execute('validate')
    }

    logout() {
        this.rpc.execute('logout').then(() => {
            window.location.reload()
        })
    }

    resetPassword(email) {
        return this.rpc.execute('resetPassword', {
            email: email
        })
    }

    changePassword(password, repeatPassword) {
        return this.rpc.execute('changePassword', {
            password: password,
            repeatPassword: repeatPassword,
        })
    }
}

export default new AuthService(rpc)
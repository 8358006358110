export default class FormModel {
    label = null
    value = null
    feedback = null
    state = null
    type = 'text'
    selectOptions = []
    selectOption = null
    selectValue = null
    placeholder = null
    loading = false

    constructor(label, params = {}) {
        this.label = label
        this.value = params.value || null
        this.type = params.type || 'text'
        this.selectOptions = params.selectOptions || []
        this.selectOption = params.selectOption || null
        this.selectValue = params.selectValue || null
        this.placeholder = params.placeholder || null
        this.loading = params.loading || false
    }
}
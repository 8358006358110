export default new class FormService {
    processErrorField(field, name, errors) {
        for (let error of errors) {
            if (error.field === name) {
                field.state = false
                field.feedback = error.message
            }
        }
    }

    processErrorForm(form, errors) {
        for (let error of errors) {
            if (form[error.field]) {
                form[error.field].state = false
                form[error.field].feedback = error.message
            }
        }
    }

    clearForm(form) {
        for (let item in form) {
            form[item].state = null
            form[item].feedback = null
            form[item].value = null
        }
    }

    clearFormErrors(form) {
        for (let item of form) {
            item.state = null
            item.feedback = null
        }
    }

    clearField(field) {
        field.state = null
        field.value = null
        field.feedback = null
    }

    clearErrors(field) {
        field.state = null
        field.feedback = null
    }

    formToParams(form) {
        let res = {}
        for (let field in form) {
            res[field] = form[field].value
        }
        return res
    }
}